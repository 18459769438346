"use client";

import { Box, Container } from "@mui/material";
import { keyframes } from "@emotion/react";

interface Props {
  bgColor?: string;
  animationColor?: string;
}

const DotsLoading = ({ bgColor, animationColor }: Props) => {
  const bounce = keyframes`
    0%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
  `;

  const dotStyle = {
    width: "12px",
    height: "12px",
    borderRadius: "50%",
    backgroundColor: animationColor || "white",
    margin: "0 4px",
    animation: `${bounce} 1.4s infinite ease-in-out both`,
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: bgColor || "black",
      }}
    >
      <Container maxWidth="sm">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: 1,
          }}
        >
          {[0, 1, 2, 3].map((index) => (
            <Box
              key={index}
              sx={{
                ...dotStyle,
                animationDelay: `${index * 0.16}s`,
              }}
            />
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default DotsLoading;
